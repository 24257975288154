import React from 'react';
import { connect } from 'react-redux';
import { PageLoading } from 'tyb';

@connect(
  (state) => { 
    return { allMenuUrls: state.ui.allMenuUrls }
  }
)
class Root extends React.Component {

  componentDidMount() {
    // this.props.history.replace('/promotionnormal')
    const { allMenuUrls } = this.props;

    if (allMenuUrls) {
      this.props.history.replace(`/${allMenuUrls[0]}`);
    }
  }

  componentDidUpdate() {
    // const { allMenuUrls } = this.props;
    // if (allMenuUrls && allMenuUrls.length > 0) {
    //   console.log(allMenuUrls[0]);
    //   this.props.history.replace(`/${allMenuUrls[0]}`);
    // }
  }
  render() {
    const { allMenuUrls } = this.props;
    if (!allMenuUrls) {
      return (
        <PageLoading />
      )
    } 
    return (
      <div></div>
    )
  }
}

export default Root;